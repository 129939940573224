<script setup></script>

<template>
  <div class="accords text-justify">
    <div class="heading1 !mb-2">
      About accords
      <hr class="gradientLine" />
    </div>
    <div class="grid-cols-1 grid k1:grid-cols-2 gap-8">
      <div>
        <p>
          Join the movement to advance Europe’s Quantum Future through the
          Accords Programme!
        </p>
        <br />
        <p>
          We invite you to contribute to the European Quantum Ecosystem by
          participating in the Accords Programme. This platform is dedicated to
          showcasing the pioneers who are shaping the future of quantum
          technology across Europe. Whether you're involved in research,
          education, part of a startup, or an established industry leader, your
          contributions are essential. Share your innovations, spark
          collaborations, and help us define the quantum landscape across Europe
        </p>
        <br />
        <p>
          Submit your accord today and become an European Quantum Contributor!
        </p>
      </div>
      <img
        src="/quantum-computing.jpeg"
        alt=""
        class="rounded-xl w-[510px] object-cover"
      />
    </div>
    <div class="k1:flex-row flex flex-col-reverse gap-8 py-10">
      <div class="k1:w-[480px] w-full k1:h-[450px] h-full flex-shrink-0">
        <img
          src="/quantum-technology-website.jpeg"
          alt=""
          class="object-cover w-full h-full rounded-xl"
        />
      </div>
      <div class="flex-shrink-1">
        <div class="heading1 !mb-2">Recognising your contributions</div>
        <p>
          Elevate your organization's impact by joining the Accord and achieving
          quantum status instantly upon submission of your action plan. By
          sharing your insights, you help forge a powerful roadmap and establish
          best practices that benefit everyone in the field.
        </p>
        <br />
        <p>
          Celebrate your commitment to innovation with our distinguished quantum
          medal, a symbol of excellence that you can proudly display on your
          website and in your communications. Are you interested in showcasing
          your contributions? We encourage you to join us in embracing these
          principles to actively shape a future-ready quantum landscape. Click
          'Learn more' to learn what you need to do to submit an accord.
        </p>
        <div class="w-full">
          <a
            href="https://quantumready.eu/#/accords/submitAccord"
            class="button float-right main"
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
    <div class="text-center w-full items-center flex flex-col py-12">
      <h1 class="heading1 !mb-2">Principles</h1>
      <p class="max-w-3xl">
        Guided by a set of foundational principles, our Quantum Readiness
        Initiative outlines a visionary pathway towards embracing quantum
        technologies across various sectors of society. These principles serve
        as the cornerstone of our effort to create a healthy European ecosystem
        for Quantum Technologies, ensuring they become accessible,
        understandable, and beneficial for all.
      </p>
    </div>

    <div class="w-full">
      <div
        class="flex flex-col k1:flex-row mt-12 gap-2 k1:gap-12 relative h-fit"
      >
        <div>
          <h1 class="heading2 !mb-4 !text-center underline">
            Educational Accords
          </h1>
          <img
            class="object-contain h-64 w-full"
            src="/Principles_svg.svg"
            alt=""
          />
        </div>
        <!--        <div class="grid place-items-center">-->
        <!--          <p class="font-extrabold text-lg">EQRC</p>-->
        <!--        </div>-->
        <div>
          <h1 class="heading2 !mb-4 !text-center underline">
            Industrial Accords
          </h1>
          <img class="object-contain h-64" src="/industry.png" alt="" />
        </div>
      </div>
      <!--      <HowDoesItWork />-->
    </div>
  </div>
</template>
